export enum ColorsConstants {
  PRIMARY = '#003366',
  SECONDARY = '#F0F8FF',
  WHITE = 'white',
  BLACK = 'black',

  LIGHT_GREEN = '#BFEDD2',
  LIGHT_YELLOW = '#FBEEB8',
  LIGHT_RED = '#F8CAC6',
  LIGHT_PURPLE = '#ECCAFA',
  LIGHT_BLUE = '#C2E0F4',

  GREEN = '#2DC26B',
  YELLOW = '#F1C40F',
  RED = '#E03E2D',
  PURPLE = '#B96AD9',
  BLUE = '#3598DB',

  DARK_GREEN = '#169179',
  DARK_YELLOW = '#E67E23',
  DARK_RED = '#BA372A',
  DARK_PURPLE = '#843FA1',
  DARK_BLUE = '#236FA1',

  GREY_1 = '#ECF0F1',
  GREY_2 = '#CED4D9',
  GREY_3 = '#95A5A6',
  GREY_4 = '#7E8C8D',
  GREY_5 = '#34495E',
}
