import { getApi } from "../../@hooks/useApi";

type CreateFormProps = {};

export type GetConfirmResponse = {
  venda_confirmada: string;
};

export const getConfirmText = async () => {
  const { api } = await getApi({});

  const response = await api.get<GetConfirmResponse>(`/mensagem-venda`);
  return response.data;
};
