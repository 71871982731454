import { Alert, Button, Container, Form, Spinner } from "react-bootstrap";
import { ColorsConstants } from "../../themes/colorsConstants";
import { useEffect, useState } from "react";
import { StorageObjects } from "../../services/storage/constants";
import { deleteStorage, getStorage, setStorage } from "../../services/storage";
import { useAppDispatch } from "../../redux/reduxHooks";
import { useMutation } from "@tanstack/react-query";
import { loginAPI } from "../../services/api/login";
import { AuthType } from "../../@types/AuthType";
import { AxiosError } from "axios";
import { startSession } from "../../redux/slices/authSlice";
import { setAlert } from "../../redux/slices/alertSlice";

type Input = {
  email: "";
  password: "";
  remember: false;
};

export default function LoginPage() {
  const dispatch = useAppDispatch();
  const [inputs, setInputs] = useState<Input>({
    email: "",
    password: "",
    remember: false,
  });

  const getInputFromStorage = async () => {
    const inputs = await getStorage<Input>(StorageObjects.REMEMBER);
    if (inputs) {
      setInputs(inputs);
      return;
    }
  };

  const handleChange = (name: keyof Input, value: any) => {
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  useEffect(() => {
    getInputFromStorage();
  }, []);

  const handleContinuer = () => {
    let validateText = "";
    if (!inputs.email) {
      validateText += "[e-mail] ";
    }
    if (!inputs.password) {
      validateText += "[senha] ";
    }
    if (validateText) {
      dispatch(
        setAlert({
          body: "Campos Obrigatórios: " + validateText,
          isVisible: true,
          variant: "info",
        })
      );
      return;
    }

    if (inputs.remember) {
      setStorage<Input>(StorageObjects.REMEMBER, inputs);
    } else {
      deleteStorage(StorageObjects.REMEMBER);
    }

    loginMutation
      .mutateAsync({
        email: inputs.email,
        password: inputs.password,
      })
      .catch((e) => console.log(e));
  };

  const loginMutation = useMutation({
    mutationFn: loginAPI,
    onSuccess: (data) => {
      let now = new Date();
      let tenDayAfter = new Date(now);
      tenDayAfter.setDate(now.getDate() + 10);
      let sessionExpiration = tenDayAfter.getTime();

      const auth: AuthType = {
        accessToken: data.accessToken,
        sessionExpiration,
        email: data.email,
        name: data.name,
        role: data.role,
        whatsapp: data.whatsapp,
        id: data.id,
      };

      dispatch(startSession(auth));
    },
    onError: (error: AxiosError) => {
      const message = error.response?.data as string;
      dispatch(
        setAlert({
          body: `Erro ao Logar: Tente novamente mais tarde.\n`,
          isVisible: true,
          variant: "danger",
        })
      );
    },
  });

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
      }}
    >
      <Container
        style={{
          border: "1px solid gray",
          borderRadius: 10,
          maxWidth: "400px",
          boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
          margin: 10,
          padding: 20,
        }}
      >
        {loginMutation.isPending && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        )}

        {!loginMutation.isPending && (
          <>
            <div style={{ width: "100%", textAlign: "center" }}>
              <span
                style={{
                  fontWeight: "bold",
                  color: ColorsConstants.PRIMARY,
                }}
              >
                SUCATAS FERRAZ
              </span>
            </div>

            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>E-mail</Form.Label>
                <Form.Control
                  type="email"
                  value={inputs.email}
                  onChange={(e) => handleChange("email", e.target.value)}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label htmlFor="inputPassword5">Senha</Form.Label>
                <Form.Control
                  type="password"
                  id="inputPassword5"
                  aria-describedby="passwordHelpBlock"
                  value={inputs.password}
                  onChange={(e) => handleChange("password", e.target.value)}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Check
                  type="checkbox"
                  label="Lembrar usuário"
                  id={`remember`}
                  checked={inputs.remember}
                  onClick={(e) => handleChange("remember", !inputs.remember)}
                />
              </Form.Group>
              <Button
                variant="primary"
                style={{
                  width: "100%",
                  color: ColorsConstants.WHITE,
                  backgroundColor: ColorsConstants.PRIMARY,
                }}
                onClick={handleContinuer}
              >
                Entrar
              </Button>
            </Form>
          </>
        )}
      </Container>
    </div>
  );
}
