import { Button, Form, ListGroup, Spinner } from "react-bootstrap";
import { useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { apiKeys } from "../../services/api/_api-keys";
import { CommoditeNote, NoteType, ProviderNote } from "../../@types/NoteType";
import { getNotes } from "../../services/api/get-notes";
import { formatISODate } from "../../utils/date";
import { CiExport } from "react-icons/ci";
import NotePDFComponent from "./NotePDF";
import jsPDF from "jspdf";

export default function NotesPage() {
  const [show, setShow] = useState<boolean>(false);
  const [filterText, setFilterText] = useState<string>("");

  const { data: notes, isLoading } = useQuery<NoteType[]>({
    queryKey: [apiKeys.getNotes],
    queryFn: async () => await getNotes(),
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
  });

  const notesFiltred = useMemo(() => {
    if (!notes) return [];

    return notes.filter((note) => {
      const provider: ProviderNote = JSON.parse(note.dados_fornecedor);

      return (
        provider.nome.includes(filterText) ||
        provider.cnpj_cpf.includes(filterText) ||
        formatISODate(note.created_at).includes(filterText)
      );
    });
  }, [notes, filterText]);

  const downloadPdf = async (note: NoteType) => {
    const content = document.getElementById("nota-" + note.id);

    if (content) {
      // Cria um iframe oculto para renderizar o conteúdo HTML
      const iframe = document.createElement("iframe");
      iframe.style.position = "absolute";
      iframe.style.left = "-9999px";
      iframe.style.top = "-9999px";
      iframe.style.width = "210mm"; // Largura A4
      iframe.style.height = "297mm"; // Altura A4
      document.body.appendChild(iframe);

      const iframeDocument =
        iframe.contentDocument || iframe.contentWindow?.document;

      if (iframeDocument) {
        iframeDocument.open();
        iframeDocument.write("<html><head><title>PDF</title></head><body>");
        iframeDocument.write(content.innerHTML);
        iframeDocument.write("</body></html>");
        iframeDocument.close();

        // Aguarda o iframe carregar o conteúdo
        setTimeout(() => {
          const doc = new jsPDF({
            unit: "mm",
            format: [210, 297], // A4 size in mm
          });

          doc.html(iframeDocument.body, {
            callback: (pdf) => {
              pdf.save("contrato.pdf");
            },
            x: 5, // Margem esquerda
            y: 5, // Margem superior
            width: 200, // Largura da página A4 menos as margens 210
            windowWidth: document.documentElement.scrollWidth,
            autoPaging: true,
          });
        }, 500); // Ajuste o tempo conforme necessário
      }
    } else {
      console.error('Element with id "content" not found.');
    }
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
        }}
      >
        <div
          style={{
            border: "1px solid gray",
            borderRadius: 10,
            boxShadow:
              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            margin: 20,
            padding: 20,
            width: "100%",
          }}
        >
          <div style={{ fontSize: 22, fontWeight: "bold", marginBottom: 10 }}>
            Notas
          </div>

          <>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Procurar:</Form.Label>
              <Form.Control
                type="email"
                placeholder="Procurar notas por fornecedor ou data de criação..."
                value={filterText}
                onChange={(e) => setFilterText(e.target.value)}
              />
            </Form.Group>

            {isLoading && (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            )}

            <div style={{ width: "100%" }}>
              <ListGroup
                horizontal
                style={{ width: "100%", fontWeight: "bold" }}
              >
                <ListGroup.Item style={{ width: "50%" }}>
                  Fornecedor
                </ListGroup.Item>
                <ListGroup.Item style={{ width: "25%" }}>
                  Criada Em
                </ListGroup.Item>
                <ListGroup.Item style={{ width: "25%" }}>Opções</ListGroup.Item>
              </ListGroup>

              {notesFiltred?.map((note) => {
                const provider: ProviderNote = JSON.parse(
                  note.dados_fornecedor
                );
                const commodite: CommoditeNote = JSON.parse(
                  note.entrada_mercadoria
                );

                return (
                  <ListGroup horizontal key={`list-${note.id}`}>
                    <ListGroup.Item style={{ width: "50%" }}>
                      {provider.cnpj_cpf} - {provider.nome}
                    </ListGroup.Item>
                    <ListGroup.Item style={{ width: "25%" }}>
                      {formatISODate(note.created_at)}
                    </ListGroup.Item>

                    <ListGroup.Item
                      style={{
                        width: "25%",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <NotePDFComponent note={note} />
                      <Button
                        variant="outline-primary"
                        onClick={() => {
                          downloadPdf(note);
                        }}
                      >
                        Contrato PDF
                        <CiExport style={{ marginLeft: 5 }} />
                      </Button>
                      {note.url_pdf && (
                        <Button
                          onClick={() => {
                            window.open(note.url_pdf, "_blank");
                          }}
                        >
                          NF-e PDF
                          <CiExport style={{ marginLeft: 5 }} />
                        </Button>
                      )}
                    </ListGroup.Item>
                  </ListGroup>
                );
              })}
            </div>
          </>
        </div>
      </div>
    </>
  );
}
