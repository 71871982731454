import { Alert } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../redux/reduxHooks";
import { setAlert } from "../../redux/slices/alertSlice";
import { useEffect } from "react";

export default function AlertComponent() {
  const alert = useAppSelector((state) => state.alert.value);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (alert?.isVisible) {
      hideAlert();
    }
  }, [alert?.isVisible]);

  const hideAlert = () => {
    setTimeout(() => {
      dispatch(
        setAlert({
          body: "",
          isVisible: false,
          variant: "info",
        })
      );
    }, 3000);
  };

  if (!alert || !alert.isVisible) {
    return <></>;
  }

  return (
    <Alert
      key={alert.variant}
      variant={alert.variant}
      style={{
        position: "fixed",
        bottom: 0,
        right: 0,
        marginRight: 10,
        zIndex: 9999999
      }}
    >
      {alert.body}
    </Alert>
  );
}
