export function timer(timeToEndInTimeStamp: number, callback: () => void) {
  const timeToStop: Date = new Date(timeToEndInTimeStamp);
  const now: Date = new Date();

  const leftTime: number = timeToStop.getTime() - now.getTime();

  if (leftTime <= 0) {
    callback();
  } else {
    setTimeout(callback, leftTime);
  }
}
