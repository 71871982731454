import { getApi } from "../../@hooks/useApi";

type CreateFormProps = {
  tipoTabela: "atacado" | "varejo";
  produtos: string;
};

export type CreateFormProducts = {};

export type CreateFormResponse = {};

export const importCSV = async (data: CreateFormProps) => {
  const { api } = await getApi({});

  const response = await api.put<CreateFormResponse>(
    `/produtos/atualizar-valores`,
    data
  );
  return response.data;
};
