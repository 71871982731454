import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../redux/reduxHooks";
import { logout, verifySession } from "../redux/slices/authSlice";
import { timer } from "../utils/timer";
import AuthNavigation from "./_Auth";
import PrivateNavigation from "./_Private";
import { Spinner } from "react-bootstrap";
import NavComponent from "../components/Nav";

export default function Navigation() {
  const [loading, setLoading] = useState<boolean>(true);
  const auth = useAppSelector((state) => state.auth.value);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(verifySession()).finally(() => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (auth) {
      timer(auth.sessionExpiration, () => {
        dispatch(logout());
      });
    }
  }, [auth]);

  if (loading) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  }

  if (auth) {
    return (
      <>
        <NavComponent />
        <PrivateNavigation />
      </>
    );
  }

  return <AuthNavigation />;
}
