import { getApi } from "../../@hooks/useApi";

type GetProductsProps = {
  id: number;
};

export type GetProductResponse = {
  codigo_familia_omie: number;
  codigo_omie: string;
  codigo_produto_omie: string;
  data_alteracao: string;
  descricao_produto: string;
  id: number;
  unidade: string;
  valor_unitario: number;
  valor_unitario_atacado: number;
};

export const getProductsByIdFamilyAPI = async (data: GetProductsProps) => {
  const { api } = await getApi({});

  const response = await api.get<GetProductResponse[]>(
    `familias-produtos/${data.id}/produtos`
  );

  return response.data;
};
