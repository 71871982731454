import { AxiosError } from "axios";
import { getApi } from "../../@hooks/useApi";
import { UserRole } from "../../@types/UsersType";

type CreateUserProps = {
  email: string;
  password: string;
};

type CreateUserResponse = {
  accessToken: string;
  email: string;
  name: string;
  role: UserRole;
  whatsapp: string;
  id: number;
};

export const loginAPI = async (data: CreateUserProps) => {
  const { api } = await getApi({});

  const response = await api.post<CreateUserResponse>(`/login`, data);
  return response.data;
};
