import { getApi } from "../../@hooks/useApi";

type UpdateProductPriceProps = {
  id: number;
  valor_unitario_atacado: number;
};

type UpdateProductPriceResponse = {};

export const updateProductPriceAtacado = async (
  data: UpdateProductPriceProps
) => {
  const { api } = await getApi({});

  const response = await api.put<UpdateProductPriceResponse>(
    `/produtos/${data.id}`,
    { valor_unitario_atacado: data.valor_unitario_atacado }
  );

  return response.data;
};
