import { getApi } from '../../@hooks/useApi';
import { ProductFamilyType } from '../../@types/ProductFamilyType';

type GetFamilyProductProps = {};

export type GetFamilyProductResponse = {
  famCadastro: ProductFamilyType[];
};

export const getFamilyProduct = async () => {
  const {api} = await getApi({});

  const response = await api.get<GetFamilyProductResponse>(`/omie/familias-produtos`);
  return response.data.famCadastro;
};
