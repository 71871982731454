import { getApi } from "../../@hooks/useApi";

type CreateFormProps = {
  venda_confirmada: string;
};

export type CreateFormProducts = {};

export type CreateFormResponse = {};

export const createConfirmText = async (data: CreateFormProps) => {
  const { api } = await getApi({});

  const response = await api.post<CreateFormResponse>(`/mensagem-venda`, data);
  return response.data;
};
