import axios from "axios";
import { getStorage } from "../services/storage";
import { StorageObjects } from "../services/storage/constants";
import { AuthType } from "../@types/AuthType";

type Props = {
  key?: "token";
};

export const baseUrl = "https://app.ferrazsucatas.com.br:3000/api";

export const getApi = async ({ key = "token" }: Props) => {
  const auth = await getStorage<AuthType>(StorageObjects.AUTH);

  const api = axios.create({
    baseURL: baseUrl,
    headers: {
      Authorization: `Bearer ${auth?.accessToken}`,
    },
  });

  api.interceptors.response.use(
    (response: any) => response,
    (error: any) => {
      if (error.response && error.response.status === 404) {
      }
      if (error.response && error.response.status === 401) {
        axios.CancelToken.source().cancel();
      }
      return Promise.reject(error);
    }
  );

  return { api };
};
