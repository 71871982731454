import {
  CommoditeNote,
  NoteType,
  ProviderNote,
} from "../../../@types/NoteType";
import { formatISODate } from "./../../../utils/date";
import { convertToKg } from "../../../utils/weight";
import { baseUrl } from "./../../../@hooks/useApi";

type Props = {
  note: NoteType;
};

export default function NotePDFComponent({ note }: Props) {
  const provider: ProviderNote = JSON.parse(note.dados_fornecedor);
  const commodite: CommoditeNote = JSON.parse(note.entrada_mercadoria);

  return (
    <div style={{ display: "none" }} id={"nota-" + note.id}>
      <div
        style={{
          paddingTop: 35,
          paddingBottom: 65,
          fontSize: 22,
          fontFamily: "sans-serif",
        }}
      >
        <div
          style={{
            justifyContent: "space-between",
            display: "flex",
            flexDirection: "row",
            fontWeight: "bold",
          }}
        >
          <p>{formatISODate(note.created_at)}</p>
          <p>{note.numero_nota}</p>
        </div>

        <p style={{ fontWeight: "bold", marginTop: 20 }}>
          Dados Básicos do Fornecedor
        </p>
        <p>
          Nome: {provider.nome} <br />
          Telefone: {provider.whatsapp} <br />
          CPF/CNPJ: {provider.cnpj_cpf}
          <br />
          E-mail: {provider.email}
          <br />
        </p>

        <p style={{ fontWeight: "bold" }}>Endereço do Fornecedor</p>
        <p>
          Estado: {provider.estado} <br />
          Cidade: {provider.cidade}
          <br />
          Bairro: {provider.bairro}
          <br />
          Endereço: {provider.endereco}
          <br />
          Nº: {provider.numero}
          <br />
          Complemento: {provider.complemento}
          <br />
        </p>

        <p style={{ fontWeight: "bold" }}>Venda Por:</p>

        <p>{note.tabela_preco.toLocaleUpperCase()}</p>

        <p style={{ marginTop: 20, fontWeight: "bold" }}>Produtos</p>

        {commodite.produtos.map((produto, i) => {
          return (
            <div key={i + 1}>
              <p style={{ fontWeight: "bold" }}>Nome: {produto.nome_produto}</p>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <img
                  style={{
                    maxWidth: 300,
                    maxHeight: 350,
                    aspectRatio: 0.5,
                  }}
                  src={baseUrl + produto.foto}
                />
              </div>

              <p>
                Peso Bruto: {convertToKg(produto.quantidade)}
                <br />
                Preço:{" "}
                {produto.valUnit.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
                <br />
                Desconto: {convertToKg(Number(produto.desconto))}
                <br />
                {produto.obs && (
                  <>
                    <span style={{ fontWeight: "bold" }}>{produto.obs}</span>
                    <br />
                  </>
                )}
              </p>
            </div>
          );
        })}

        <p style={{ marginTop: 20, fontSize: 22, fontWeight: "bold" }}>
          OBS: {commodite.obs}
        </p>
      </div>

      <div
        style={{
          fontSize: 22,
          fontFamily: "sans-serif",
          width: "100%" /* Largura da página A4 */,
          height: "29.7cm" /* Altura da página A4 */,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div>
          <p style={{ fontWeight: "bold", textAlign: "center" }}>
            CONTRATO DE COMPRA E VENDA
          </p>

          <p style={{ textAlign: "justify" }}>
            Eu, fornecedor identificado nos "dados do fornecedor" declaro que os
            materiais adotados nesse romaneio são de{" "}
            <span style={{ fontWeight: "bold", textDecoration: "underline" }}>
              procedência lícita e de minha propriedade, sob as quais responde
              cívil e criminalmente
            </span>{" "}
            e desejar efetuar a venda para empresa Eliel Monteiro Ferraz
            reciclagem de CNPJ 12.901.483/0001-84.
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontWeight: "bold" }}>
              Autorizo a emissão de nota fiscal de entrada em meu nome
            </span>{" "}
            para as mercadorias listadas acima.
          </p>
          <p style={{ textAlign: "justify" }}>
            Declaro estar ciente que o pagamento ocorre por melo digital (Pix ou
            transferência bancária) a ser feito em conta informada nesto
            romanelo. A empresa Eliel Montetro Ferraz Recicagem -
            12.901.483/0001-84 se compromete a realzação do pagamento entre:{" "}
            <span style={{ fontWeight: "bold", textDecoration: "underline" }}>
              08:00 às 19:00 horas
            </span>
            . Após conclusão do pagamento a comprovação bancária é válida para
            constatação de quitação do valor devido pela negociação (se
            necessario solicitar comprovante).
          </p>
          <p style={{ textAlign: "justify" }}>
            Abaixo assino como fornecedor responsável civil e criminalmente
            sobre os materiais aqui comercializados, após conferência dos dados
            e declarando verdadeira todas as informações e concordando com a
            venda dos produtos dentro dos termos e condiçõos supracitadas firmo
            a presente venda.
          </p>

          <p
            style={{
              marginTop: 20,
              marginBottom: 10,
            }}
          >
            Assinatura do Fornecedor:
          </p>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              alignItems: "center",
            }}
          >
            <img
              style={{
                width: 600,
              }}
              src={baseUrl + note.media_assinatura_fornecedor}
            />
          </div>

          <p
            style={{
              marginTop: 20,
              marginBottom: 10,
            }}
          >
            Assinatura do Conferente:
          </p>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              alignItems: "center",
            }}
          >
            <img
              style={{
                width: 600,
              }}
              src={baseUrl + note.media_assinatura_contratante}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
