import { Button, Container, Form, Spinner } from "react-bootstrap";
import { ColorsConstants } from "../../themes/colorsConstants";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../redux/reduxHooks";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { setAlert } from "../../redux/slices/alertSlice";
import { createConfirmText } from "../../services/api/create-confirm-text";
import { apiKeys } from "../../services/api/_api-keys";
import {
  GetConfirmResponse,
  getConfirmText,
} from "../../services/api/get-confirm-text";

type Input = {
  confirmText: string;
};

export default function MessagesPage() {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const [inputs, setInputs] = useState<Input>({
    confirmText: "",
  });

  const handleChange = (name: keyof Input, value: any) => {
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const handleContinuer = () => {
    let validateText = "";
    if (!inputs.confirmText) {
      validateText += "[mensagem de confirmação de venda] ";
    }

    if (validateText) {
      dispatch(
        setAlert({
          body: "Campos Obrigatórios: " + validateText,
          isVisible: true,
          variant: "info",
        })
      );
      return;
    }

    confirmMutation
      .mutateAsync({
        venda_confirmada: inputs.confirmText,
      })
      .catch((e) => console.log(e));
  };

  const confirmMutation = useMutation({
    mutationFn: createConfirmText,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [apiKeys.getConfirmText],
      });
      dispatch(
        setAlert({
          body: `Sucesso ao Salvar Mensagem: Mensagem salva com sucesso.\n`,
          isVisible: true,
          variant: "success",
        })
      );
    },
    onError: (error: AxiosError) => {
      const message = error.response?.data as string;
      dispatch(
        setAlert({
          body: `Erro ao Salvar Mensagem: Tente novamente mais tarde.\n`,
          isVisible: true,
          variant: "danger",
        })
      );
    },
  });

  const { data: message, isLoading } = useQuery<GetConfirmResponse>({
    queryKey: [apiKeys.createConfirmText],
    queryFn: async () => await getConfirmText(),
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
  });

  useEffect(() => {
    if (message) {
      setInputs({
        confirmText: message.venda_confirmada,
      });
    }
  }, [message]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        marginTop: 10,
      }}
    >
      <Container
        style={{
          border: "1px solid gray",
          borderRadius: 10,
          maxWidth: "600px",
          boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
          margin: 10,
          padding: 20,
        }}
      >
        {(confirmMutation.isPending || isLoading) && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        )}

        {!confirmMutation.isPending && (
          <>
            <div style={{ fontSize: 22, fontWeight: "bold", marginBottom: 10 }}>
              Mensagem de Confirmação
            </div>

            <div style={{ marginBottom: 10 }}>
              Mensagens para operação de confirmação de venda
            </div>

            <Form style={{ marginTop: 20 }}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label style={{ fontWeight: "bold" }}>
                  Mensagem de venda confirmada
                </Form.Label>
                <Form.Label>
                  Utilize a tag {`<<fornecedor>>`} caso queira usar o nome do
                  fornecedor na mensagem e a tag {`<<nota>>`} para utilizar o
                  número da nota
                </Form.Label>
                <Form.Control
                  as="textarea"
                  style={{ minHeight: 200 }}
                  value={inputs.confirmText}
                  placeholder="Sr. <<fornecedor>>, sua venda foi confirmada! A nota é <<nota>>."
                  onChange={(e) => handleChange("confirmText", e.target.value)}
                />
              </Form.Group>
              <Button
                variant="primary"
                style={{
                  width: "100%",
                  color: ColorsConstants.WHITE,
                  backgroundColor: ColorsConstants.PRIMARY,
                }}
                onClick={handleContinuer}
              >
                Salvar Mensagem
              </Button>
            </Form>
          </>
        )}
      </Container>
    </div>
  );
}
