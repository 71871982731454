import { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { GetProductResponse } from "../../../services/api/get-products-by-id-family";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useAppDispatch } from "../../../redux/reduxHooks";
import { setAlert } from "../../../redux/slices/alertSlice";
import { apiKeys } from "../../../services/api/_api-keys";
import { updateProductPriceVerejo } from "../../../services/api/update-product-price-varejo";
import { updateProductPriceAtacado } from "../../../services/api/update-product-price-atacado";
import { InputFileCustom } from "../../../components/InputFile";
import { ColorsConstants } from "../../../themes/colorsConstants";
import { importCSV } from "../../../services/api/import-csv";

type Props = {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function ModalImportCSVComponent({ show, setShow }: Props) {
  const handleClose = () => setShow(false);
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const [type, setType] = useState<"atacado" | "varejo">();
  const [text, setText] = useState<string>("");

  // Regex para validar preços como "0", "0.0", "555.55"
  const importMutation = useMutation({
    mutationFn: importCSV,
    onSuccess: (data) => {
      setType(undefined);
      setText("");
      dispatch(
        setAlert({
          body: `Produtos importados com sucesso!.\n`,
          isVisible: true,
          variant: "success",
        })
      );
      queryClient.invalidateQueries({
        queryKey: [apiKeys.getProductsByIdFamilyAPI],
      });
      handleClose();
    },
    onError: (error: AxiosError) => {
      const message = error.response?.data as string;
      dispatch(
        setAlert({
          body: `Erro ao importar produtos: Tente novamente mais tarde.\n${message}`,
          isVisible: true,
          variant: "danger",
        })
      );
    },
  });

  const handleContinuer = () => {
    let validateText = "";
    if (!type) {
      validateText += "[tipo] ";
    }

    if (!text) {
      validateText += "[arquivo] ";
    }

    if (validateText || !type) {
      dispatch(
        setAlert({
          body: "Campos Obrigatórios: " + validateText,
          isVisible: true,
          variant: "info",
        })
      );
      return;
    }

    importMutation
      .mutateAsync({
        tipoTabela: type,
        produtos: text,
      })
      .catch((e) => console.log(e));
  };

  const onGetCSVFile = async (file: File) => {
    const text = (await file.text()) as string;
    const textFormatted = text.replace(/,/g, ";");
    const finalText = textFormatted.replace(/"(.*?)"/g, (match, p1) => {
      return p1.replace(/;/g, ",");
    });

    setText(finalText);
    return;
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Importar Produtos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label htmlFor="inputPassword5">Produtos de:</Form.Label>
          <div key={`inline-checkbox`} className="mb-3">
            <Form.Check
              inline
              label="Atacado"
              type={"checkbox"}
              id={`inline-checkbox-1`}
              checked={type === "atacado"}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.checked) {
                  setType("atacado");
                }
              }}
            />
            <Form.Check
              inline
              label="Varejo"
              type={"checkbox"}
              id={`inline-checkbox-2`}
              checked={type === "varejo"}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.checked) {
                  setType("varejo");
                }
              }}
            />
          </div>

          <Form.Group
            className="mb-3"
            controlId="exampleForm.ControlInput1"
            style={{ marginTop: 5 }}
          >
            <InputFileCustom onGetFile={onGetCSVFile} accept=".csv" />
            <div style={{ fontSize: 11 }}>
              <p>
                • O arquivo deve estar no formato de planilha do excel{" "}
                <strong>.CSV</strong>. <br /> • Os dados devem estar no formato:{" "}
                <strong>'codigo_produto_omie', 'valor_unitario'</strong>
              </p>
            </div>
          </Form.Group>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button onClick={handleContinuer}>Importar</Button>

            <Button variant={"outline-primary"} onClick={handleClose}>
              Cancelar
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
