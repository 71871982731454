import { getApi } from "../../@hooks/useApi";

type UpdateProductPriceProps = {
  id: number;
  valor_unitario: number;
};

type UpdateProductPriceResponse = {};

export const updateProductPriceVerejo = async (data: UpdateProductPriceProps) => {
  const { api } = await getApi({});

  const response = await api.put<UpdateProductPriceResponse>(
    `/produtos/${data.id}`,
    { valor_unitario: data.valor_unitario }
  );

  return response.data;
};
