import { useState } from "react";
import { ColorsConstants } from "../../themes/colorsConstants";

type Props = {
  onGetFile: (file: File) => void;
} & React.InputHTMLAttributes<HTMLInputElement>;

export function InputFileCustom({ onGetFile, ...rest }: Props) {
  const [fileName, setFileName] = useState("...");

  const onChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    const file = files?.[0];

    if (file) {
      onGetFile(file);
      setFileName(file.name);
    }
  };

  return (
    <label
      style={{
        border: "1px solid black",
        maxWidth: "100%",
        display: "flex",
        alignItems: "center",
        borderRadius: "5px",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          height: "100%",
          padding: "0 10px",
          backgroundColor: ColorsConstants.PRIMARY,
          color: ColorsConstants.WHITE,
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          fontWeight: "bold",
          whiteSpace: "nowrap",
          minHeight: "35px",
        }}
      >
        Escolha o arquivo
      </div>
      <input
        type="file"
        onChange={onChangeFile}
        {...rest}
        style={{ display: "none" }}
      />
      <span
        style={{
          width: "100%",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          padding: "0 10px",
          wordWrap: "normal",
        }}
      >
        {fileName}
      </span>
    </label>
  );
}
