export const apiKeys = {
  createUser: "create-user",
  searchProviderByCPF: "search-provider-by-cpf",
  loginAPI: "loginAPI",
  signUp: "sign-up",
  getProductsAPI: "get-products",
  createForm: "create-form",
  getUsers: "get-users",
  getNotes: "get-notes",
  getUsersByName: "get-users-by-name",
  updateUsers: "update-users",
  toggleUserStatus: "toggle-user-status",
  changePassword: "change-password",
  getCSV: "get-csv",
  getFamilyProduct: "get-family-product",
  getProductsByIdFamilyAPI: "get-products-by-id-family",
  updateProductPriceVarejo: "update-product-price-varejo",
  createConfirmText: "create-confirm-text",
  getConfirmText: "get-confirm-text",
  updateProductPriceAtacado: "update-product-price-atacado",
  importCSV: "import-csv",
};
