import {PayloadAction, createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {ReduxConstants} from '../reduxConstants';
import {deleteStorage, getStorage, setStorage} from '../../services/storage';
import {StorageObjects} from '../../services/storage/constants';
import { AuthType } from '../../@types/AuthType';

interface AuthState {
  value: AuthType | undefined;
}

const initialState: AuthState = {
  value: undefined,
};

const verifySession = createAsyncThunk(
  `${ReduxConstants.AUTH}/verifySession`,
  async () => {
    const auth = await getStorage<AuthType>(StorageObjects.AUTH);
    return auth;
  },
);

const authSlice = createSlice({
  name: ReduxConstants.AUTH,
  initialState,
  reducers: {
    startSession(state, action: PayloadAction<AuthType>) {
      const newAuth = {...action.payload};
      setStorage<AuthType>(StorageObjects.AUTH, newAuth);
      state.value = newAuth;
    },
    logout(state) {
      deleteStorage(StorageObjects.AUTH);
      state.value = undefined;
    },
  },
  extraReducers: builder => {
    builder.addCase(verifySession.fulfilled, (state, action) => {
      state.value = action.payload;
    });
  },
});

export const {startSession, logout} = authSlice.actions;
export {verifySession};
export default authSlice.reducer;
