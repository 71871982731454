import { Navigate, Route, Routes } from "react-router";
import LoginPage from "../../pages/Login";
import { AuthNavigationNames } from "../constants/auth.navigation";

export default function AuthNavigation() {
  return (
    <Routes>
      <Route path={AuthNavigationNames.LOGIN} element={<LoginPage />} />
      <Route
        path={AuthNavigationNames.NOT_FOUND}
        element={<Navigate to={AuthNavigationNames.LOGIN} />}
      />
    </Routes>
  );
}
