import "./App.css";
import Navigation from "./navigation";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Provider } from "react-redux";
import { store } from "./redux/reduxStore";
import AlertComponent from "./components/Alert";
import { PDFViewer } from "@react-pdf/renderer";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <Navigation />
        <AlertComponent />
      </Provider>
    </QueryClientProvider>
  );
}

export default App;
