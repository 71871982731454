import { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { GetProductResponse } from "../../../services/api/get-products-by-id-family";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useAppDispatch } from "../../../redux/reduxHooks";
import { setAlert } from "../../../redux/slices/alertSlice";
import { apiKeys } from "../../../services/api/_api-keys";
import { updateProductPriceVerejo } from "../../../services/api/update-product-price-varejo";
import { updateProductPriceAtacado } from "../../../services/api/update-product-price-atacado";

type Props = {
  product: GetProductResponse;
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  type: "atacado" | "varejo";
};

export default function ModalComponent({
  product,
  show,
  setShow,
  type,
}: Props) {
  const handleClose = () => setShow(false);
  const [newPrice, setNewPrice] = useState<string>(
    type === "varejo"
      ? product.valor_unitario.toString()
      : product.valor_unitario_atacado.toString()
  );
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  useEffect(() => {
    setNewPrice(
      type === "varejo"
        ? product.valor_unitario.toString()
        : product.valor_unitario_atacado.toString()
    );
  }, [product, type]);

  const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    // Regex para validar preços como "0", "0.0", "555.55"
    const pricePattern = /^(?:[1-9]\d*|0)?(?:\.\d{0,2})?$/;

    if (pricePattern.test(value)) {
      setNewPrice(value);
    }
  };

  // Regex para validar preços como "0", "0.0", "555.55"
  const updateVarejoMutation = useMutation({
    mutationFn: updateProductPriceVerejo,
    onSuccess: (data) => {
      handleClose();
      queryClient.invalidateQueries({
        queryKey: [apiKeys.getProductsByIdFamilyAPI],
      });
    },
    onError: (error: AxiosError) => {
      const message = error.response?.data as string;
      dispatch(
        setAlert({
          body: `Erro ao atualizar valor: Tente novamente mais tarde.\n${message}`,
          isVisible: true,
          variant: "danger",
        })
      );
    },
  });

  const updateAtacadoMutation = useMutation({
    mutationFn: updateProductPriceAtacado,
    onSuccess: (data) => {
      handleClose();
      queryClient.invalidateQueries({
        queryKey: [apiKeys.getProductsByIdFamilyAPI],
      });
    },
    onError: (error: AxiosError) => {
      const message = error.response?.data as string;
      dispatch(
        setAlert({
          body: `Erro ao atualizar valor: Tente novamente mais tarde.\n${message}`,
          isVisible: true,
          variant: "danger",
        })
      );
    },
  });

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Editar Produto</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>
            <span style={{ fontWeight: "bold" }}>Produto: </span>
            {product.descricao_produto}
          </span>
          <Form.Group
            className="mb-3"
            controlId="exampleForm.ControlInput1"
            style={{ marginTop: 5 }}
          >
            <Form.Label>Novo Preço ({type}):</Form.Label>
            <Form.Control
              type="text"
              pattern="[0-9.]*"
              value={newPrice}
              onChange={handlePriceChange}
            />
          </Form.Group>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              variant={"primary"}
              style={{ marginBottom: 5 }}
              onClick={() => {
                type === "varejo"
                  ? updateVarejoMutation
                      .mutateAsync({
                        id: product.id,
                        valor_unitario: Number(newPrice),
                      })
                      .catch((e) => console.log(e))
                  : updateAtacadoMutation
                      .mutateAsync({
                        id: product.id,
                        valor_unitario_atacado: Number(newPrice),
                      })
                      .catch((e) => console.log(e));
              }}
            >
              Alterar Preço
            </Button>

            <Button variant={"outline-primary"} onClick={handleClose}>
              Cancelar
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
