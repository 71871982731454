import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { CiLogin } from "react-icons/ci";
import { PrivateNavigationNames } from "../../navigation/constants/private.navigation";
import { Link, useLocation } from "react-router-dom";
import { useAppDispatch } from "../../redux/reduxHooks";
import { logout } from "../../redux/slices/authSlice";

export default function NavComponent() {
  const dispatch = useAppDispatch();
  const location = useLocation();

  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" data-bs-theme="dark">
      <Container
        style={{
          marginLeft: 10,
          width: "100%",
          maxWidth: "none",
          marginRight: 10,
        }}
      >
        <Navbar.Brand href="#home" style={{ color: "white" }}>
          Ferraz Sucatas
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          style={{ color: "white" }}
          color="white"
        />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Link
              to={PrivateNavigationNames.PRODUCTS}
              style={{
                textDecoration: "none",
                color: "white",
                fontWeight:
                  location.pathname === PrivateNavigationNames.PRODUCTS
                    ? "bold"
                    : "normal",
              }}
            >
              <Nav.Item
                style={{
                  color: "white",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  marginRight: 10,
                }}
              >
                Produtos
              </Nav.Item>
            </Link>

            <Link
              to={PrivateNavigationNames.NOTES}
              style={{
                textDecoration: "none",
                color: "white",
                fontWeight:
                  location.pathname === "/" + PrivateNavigationNames.NOTES
                    ? "bold"
                    : "normal",
              }}
            >
              <Nav.Item
                style={{
                  color: "white",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  marginRight: 10,
                }}
              >
                Notas
              </Nav.Item>
            </Link>

            <Link
              to={PrivateNavigationNames.MESSAGES}
              style={{
                textDecoration: "none",
                color: "white",
                fontWeight:
                  location.pathname === "/" + PrivateNavigationNames.MESSAGES
                    ? "bold"
                    : "normal",
              }}
            >
              <Nav.Item
                style={{
                  color: "white",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Mensagem de Confirmação
              </Nav.Item>
            </Link>
          </Nav>
          <Nav>
            <Nav.Link
              href="#memes"
              style={{ color: "white" }}
              onClick={() => {
                dispatch(logout());
              }}
            >
              Sair
              <CiLogin />
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
