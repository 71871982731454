import { Button, Form, ListGroup, Spinner } from "react-bootstrap";
import { useMemo, useState } from "react";
import { useAppDispatch } from "../../redux/reduxHooks";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { setAlert } from "../../redux/slices/alertSlice";
import { apiKeys } from "../../services/api/_api-keys";
import {
  GetProductResponse,
  getProductsByIdFamilyAPI,
} from "../../services/api/get-products-by-id-family";
import { ProductFamilyType } from "../../@types/ProductFamilyType";
import { getFamilyProduct } from "../../services/api/get-family-product";
import { TiPencil } from "react-icons/ti";
import ModalComponent from "./_Modal";
import { ColorsConstants } from "../../themes/colorsConstants";
import { IoReload } from "react-icons/io5";
import { getUpdateProduct } from "../../services/api/get-update-product";
import { RiImportFill } from "react-icons/ri";
import ModalImportCSVComponent from "./_ModalImportCSV";

export default function ProductsPage() {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const [show, setShow] = useState<boolean>(false);
  const [showCSVModal, setShowCSVModal] = useState<boolean>(false);

  const [type, setType] = useState<"atacado" | "varejo">();
  const [filterText, setFilterText] = useState<string>("");
  const [familyProduct, setFamillyProduct] = useState<ProductFamilyType>();
  const [productSelected, setProductSelected] = useState<GetProductResponse>();

  const { data: productsFamilies, isLoading: isLoadingFamilies } = useQuery<
    ProductFamilyType[]
  >({
    queryKey: [apiKeys.getFamilyProduct],
    queryFn: async () => await getFamilyProduct(),
  });

  const {
    data: products,
    isLoading,
    refetch,
  } = useQuery<GetProductResponse[]>({
    queryKey: [apiKeys.getProductsByIdFamilyAPI, familyProduct],
    queryFn: async () =>
      await getProductsByIdFamilyAPI({
        id: familyProduct ? familyProduct.codigo : -1,
      }),
    enabled: !!familyProduct,
  });

  const handleUpdateProduct = () => {
    updateProductsMutation.mutateAsync();
    refetch();
  };

  const productsFiltred = useMemo(() => {
    if (!products) return [];
    if (!filterText) return products;
    return products.filter((product) =>
      product.descricao_produto.includes(filterText)
    );
  }, [products, filterText]);

  const updateProductsMutation = useMutation({
    mutationFn: getUpdateProduct,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [apiKeys.getProductsByIdFamilyAPI],
      });
      dispatch(
        setAlert({
          body: `Sucesso ao atualizar produtos: produtos de acordo com o banco de dados!\n`,
          isVisible: true,
          variant: "success",
        })
      );
    },
    onError: (error: AxiosError) => {
      const message = error.response?.data as string;
      dispatch(
        setAlert({
          body: `Erro ao Atualizar Produtos: Tente novamente mais tarde.\n`,
          isVisible: true,
          variant: "danger",
        })
      );
    },
  });

  return (
    <>
      {productSelected && type && (
        <ModalComponent
          type={type}
          show={show}
          setShow={setShow}
          product={productSelected}
        />
      )}

      <ModalImportCSVComponent show={showCSVModal} setShow={setShowCSVModal} />

      <div
        style={{
          width: "100%",
          display: "flex",
        }}
      >
        <div
          style={{
            border: "1px solid gray",
            borderRadius: 10,
            boxShadow:
              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            margin: 20,
            padding: 20,
            width: "100%",
          }}
        >
          <div style={{ fontSize: 22, fontWeight: "bold", marginBottom: 10 }}>
            Produtos
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="primary"
              style={{
                marginBottom: 10,
                color: ColorsConstants.WHITE,
                backgroundColor: ColorsConstants.PRIMARY,
              }}
              onClick={() => setShowCSVModal(true)}
            >
              Importar Produtos
              <RiImportFill style={{ marginLeft: 5 }} />
            </Button>
          </div>

          <div style={{ marginBottom: 5 }}>
            Selecione uma Família de Produto:{" "}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 10,
              flexWrap: "wrap",
            }}
          >
            {isLoadingFamilies && (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            )}

            {productsFamilies?.map((family) => {
              return (
                <Button
                  variant={
                    family.codigo === familyProduct?.codigo
                      ? "primary"
                      : "outline-primary"
                  }
                  onClick={() => setFamillyProduct(family)}
                >
                  {family.nomeFamilia}
                </Button>
              );
            })}
          </div>

          {familyProduct && (
            <>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Procurar:</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Procurar Produto..."
                  value={filterText}
                  onChange={(e) => setFilterText(e.target.value)}
                />
              </Form.Group>

              {isLoading && (
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              )}

              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  variant="primary"
                  style={{
                    marginBottom: 10,
                    color: ColorsConstants.WHITE,
                    backgroundColor: ColorsConstants.PRIMARY,
                  }}
                  onClick={handleUpdateProduct}
                >
                  Atualizar Produtos
                  <IoReload style={{ marginLeft: 5 }} />
                </Button>
              </div>

              <div style={{ width: "100%" }}>
                <ListGroup
                  horizontal
                  style={{ width: "100%", fontWeight: "bold" }}
                >
                  <ListGroup.Item style={{ width: "40%" }}>
                    Descrição
                  </ListGroup.Item>
                  <ListGroup.Item style={{ width: "15%" }}>
                    Preço Varejo
                  </ListGroup.Item>
                  <ListGroup.Item style={{ width: "15%" }}>
                    Preço Atacado
                  </ListGroup.Item>
                  <ListGroup.Item style={{ width: "30%" }}>
                    Opções
                  </ListGroup.Item>
                </ListGroup>

                {productsFiltred.map((product) => {
                  return (
                    <ListGroup horizontal>
                      <ListGroup.Item style={{ width: "40%" }}>
                        {product.descricao_produto}
                      </ListGroup.Item>
                      <ListGroup.Item style={{ width: "15%" }}>
                        {product.valor_unitario.toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                          minimumFractionDigits: 2,
                        })}
                      </ListGroup.Item>
                      <ListGroup.Item style={{ width: "15%" }}>
                        {product.valor_unitario_atacado.toLocaleString(
                          "pt-BR",
                          {
                            style: "currency",
                            currency: "BRL",
                            minimumFractionDigits: 2,
                          }
                        )}
                      </ListGroup.Item>
                      <ListGroup.Item
                        style={{
                          width: "30%",
                          justifyContent: "space-between",
                          display: "flex",
                        }}
                      >
                        <Button
                          onClick={() => {
                            setType("varejo");
                            setProductSelected(product);
                            setShow(true);
                          }}
                        >
                          Preço Varejo <TiPencil />
                        </Button>
                        <Button
                          onClick={() => {
                            setType("atacado");
                            setProductSelected(product);
                            setShow(true);
                          }}
                        >
                          Preço Atacado <TiPencil />
                        </Button>
                      </ListGroup.Item>
                    </ListGroup>
                  );
                })}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
