import { getApi } from '../../@hooks/useApi';
import { NoteType } from '../../@types/NoteType';

type GetNotesProps = {};

export type GetNotesResponse = {};

export type GetProductResponse = {
};

export const getNotes = async () => {
  const {api} = await getApi({});

  const response = await api.get<NoteType[]>(`/omie/list-nota-entrada`);
  return response.data;
};


