import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ReduxConstants } from "../reduxConstants";

export type AlertType = {
  body: string;
  isVisible: boolean;
  variant:
    | "primary"
    | "secondary"
    | "success"
    | "danger"
    | "warning"
    | "info"
    | "light"
    | "dark";
};

interface AlertState {
  value: AlertType | undefined;
}

const initialState: AlertState = {
  value: undefined,
};

const alertSlice = createSlice({
  name: ReduxConstants.ALERT,
  initialState,
  reducers: {
    setAlert(state, action: PayloadAction<AlertType>) {
      const newValue = { ...action.payload };
      state.value = { ...newValue };
    },
  },
});

export const { setAlert } = alertSlice.actions;
export default alertSlice.reducer;
