export async function setStorage<T>(key: string, value: T) {
  try {
    await localStorage.setItem(key, JSON.stringify(value));
    return true;
  } catch (error) {
    console.error("Error ao armazenar dados no storage: ", error);
    return undefined;
  }
}

export async function updateStorage(key: string, value: any) {
  try {
    await localStorage.mergeItem(key, JSON.stringify(value));
    return true;
  } catch (error) {
    console.error("Error ao atualizar dados no storage: ", error);
    return undefined;
  }
}

export async function getStorage<T>(key: string) {
  try {
    const jsonValue = await localStorage.getItem(key);
    if (jsonValue !== null) {
      return JSON.parse(jsonValue) as T;
    } else {
      return undefined;
    }
  } catch (error) {
    console.error("Error ao recuperar dados do storage:", error);
    return undefined;
  }
}

export async function deleteStorage(key: string) {
  try {
    await localStorage.removeItem(key);
    return true;
  } catch (error) {
    console.error("Error ao deletar dado do storage:", error);
    return undefined;
  }
}

export async function clearStorage() {
  try {
    await localStorage.clear();
    return true;
  } catch (error) {
    console.error("Error ao apagar AsyncStorage", error);
    return false;
  }
}
