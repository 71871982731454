import {configureStore} from '@reduxjs/toolkit';
import {ReduxConstants} from './reduxConstants';
import authReducer from './slices/authSlice';
import alertSlice from './slices/alertSlice';

export const store = configureStore({
  reducer: {
    [ReduxConstants.AUTH]: authReducer,
    [ReduxConstants.ALERT]: alertSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
