import { Navigate, Route, Routes } from "react-router";
import ProductsPage from "../../pages/Products";
import { PrivateNavigationNames } from "../constants/private.navigation";
import NotesPage from "../../pages/Notes";
import MessagesPage from "../../pages/Messages";

export default function PrivateNavigation() {
  return (
    <Routes>
      <Route
        path={PrivateNavigationNames.PRODUCTS}
        element={<ProductsPage />}
      />
      <Route path={PrivateNavigationNames.NOTES} element={<NotesPage />} />
      <Route path={PrivateNavigationNames.MESSAGES} element={<MessagesPage />} />
      <Route
        path={PrivateNavigationNames.NOT_FOUND}
        element={<Navigate to={PrivateNavigationNames.PRODUCTS} />}
      />
    </Routes>
  );
}
